<template>
  <Spin fix :show="spinShow" >加载中...</Spin>
  <div class="list">
    <div class="articleList">
      <div class="articleItem" v-for="(item, index) in data" :key="index" @click="toInfo(item.id)">
        <div class="article">
          <div class="article-title">{{ item.title }}</div>
          <div class="info">
            <div class="article-auth">作者:<span v-for="(authItem, authKey) in item.adminInfo" :key="authKey">{{ authItem.admin_name }}</span></div>
            <div class="article-classification">所属分类:<span v-for="(classificationItem, classificationKey) in item.classification" :key="classificationKey">{{ classificationItem.name }}</span></div>
            <div class="article-time">创建时间:{{ formatDate(item.created_at) }}   修改时间:{{ formatDate(item.updated_at) }}</div>
          </div>
          <div class="article-cover">
            <img :src="item.cover" alt="">
          </div>
        </div>
        <div class="articleInfo">{{ toText(item.content) }}</div>
      </div>
    </div>
    <div class="loadMore">
      <Space v-if="!isLast" direction="vertical" type="flex">
        <Button long :loading="loading" @click="toLoading">
          <span v-if="!loading">加载更多</span>
          <span v-else>加载中...</span>
        </Button>
      </Space>
      <Divider v-if="isLast" plain>暂无更多数据</Divider>
    </div>
  </div>
</template>

<script>
import contentRequest from "../../request/apiRequest/contentRequest";
import moment from "moment/moment";

export default {
  name: "index",
  data() {
    return {
      loading: false,
      data: [],
      spinShow: true,
      lastId: 0,
      isLast: false,
    }
  },
  methods:{
    getContentList(id = 0, limit = 50) {
      const that = this;
      contentRequest.Index(id, limit).then((res) => {
        if (res.status === 0) {
          // console.log(res.data);
          // this.data.push(res.data);
          // console.log(this.data)
          // this.data = res.data;
          if (res.data.length === 0) {
            this.isLast = true;
          } else {
            res.data.forEach(function (value) {
              that.data.push(value);
            })
            this.lastId = this.data[this.data.length - 1].id;
          }
        }
        if (id === 0) {
          this.spinShow = false;
        }
        this.loading = false;
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    brief: function(value) {
      return value.substr(0, 35) + '...';
    },
    toText: function (value) {
      return value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
    },
    toInfo: function (id) {
      this.$router.push(`/content/info/${id}/0`);
    },
    toLoading () {
      this.loading = true;
      this.getContentList(this.lastId);
    },
  },
  mounted() {
    this.getContentList();
  }
}
</script>

<style scoped>
.list {
  width: 60%;
}
.articleList {
  width: 100%;
}
.articleItem{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
  box-sizing: border-box;
}
.article {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.article-title {
  font-size: 16px;
  font-weight: 500;
  color: #42b983;
  text-align: justify;
  word-wrap:break-word;
}
.article-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  height: 100%;
  margin-right: 10px;
}
.article-cover img {
  width: 100%;
}
.article-content, .article-time {
  font-size: 14px;
}
.article-time, .article-auth, .article-classification {
  color: #c5c5c5;
}

.info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.articleInfo {
  margin-top: 10px;
  /* 第一步：弹性伸缩盒子模型展示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-line-clamp: 3;
  /* 设置或检索伸缩盒子对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
  /* 第二部：多出的部分隐藏 */
  overflow: hidden;
  /* 第三步：加上省略号 */
  text-overflow: ellipsis;
}

.articleItem {
  cursor:pointer;
}

.loadMore {
  margin-top: 20px;
}

</style>
